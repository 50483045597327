<template>
  <div class="main-content" >
    
    <h1 class="mb-3 text-26" id="usermanagement_tittle">{{ $t("Quick Access Management") }}</h1>    

    <b-row style="height: 100%;overflow:hidden" id="usermanagement_table">
    <b-table 
             ref="table"
             :busy="isLoading" 
             hover 
             sm 
             borderless                            
             responsive
             light 
             class="w-auto" 
             style="margin-bottom: 4rem;"
             :items="$store.state.user.knownUsers ? $store.state.user.knownUsers : []" 
             :fields="fields" 
             @row-hovered="rowHovered"
             @row-clicked="rowHovered"
             >      
      <template v-slot:table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>{{ $t('Loading...') }}</strong>
        </div>
        </template>

      <template v-slot:cell(email)="row">
        <div class="d-flex flex-row">
              <div class="mr-auto p-2">
                {{ row.item.email || row.item.username }}
              </div>
        </div>              
        
      </template>

      


      <template v-slot:cell(username)="row">
          <div class="d-flex flex-row">
              <div class="mr-auto p-2">{{ (row.item.firstName && row.item.lastName) ? (row.item.firstName + ' ' + row.item.lastName) : row.item.username }}</div>                            
          </div>
      </template>



      <template v-slot:cell(forget)="row">
        <b-button variant="light" size="sm" right @click="deleteUser(row.item)" class="btndelete right">
          <font-awesome-icon icon="trash-alt"  />
          {{ $t('Forget User on this Kiosk') }}
        </b-button>
      </template>

      <template v-slot:cell(resetLockPattern)="row">
        <b-button v-if="$store.state.user.currentUser.username === row.item.username" variant="info" size="sm" right @click="revokePattern(row.item)" class="btndelete right">
          <font-awesome-icon icon="refresh-alt"  />
          {{ $t('Change Pattern') }}
        </b-button>
      </template>

    </b-table>
    </b-row>
  </div> 
</template>


<script>
import { SPService } from "@/services/sp.service";
import { AuthService } from "@/services/auth.service";
import UserEditionModal from "@/components/ISA/admin/UserEditionModal";
import UserDeletionModal from "@/components/ISA/admin/UserDeletionModal";
import SendInvitationModal from "@/components/ISA/admin/SendInvitationModal";
import PendingInvitationModal from "@/components/ISA/admin/PendingInvitationModal";
import { API_URL } from '@/.env';

export default {
  name: "UserManagement",  
    metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "ISA Users Management"
  },  
  data() {
    return {
      users: null,
        isLoading: false,
        hoveredRow: null
    };
  },
  computed: {

    fields() {
          let fields = [];
          
      fields.push({
            key: 'username',
            label: this.$t('User Name'),
            sortable: true
          });
          

      fields.push({
            key: 'resetLockPattern',
            label: '',
            sortable: false
          });
          
      fields.push({
            key: 'forget',
            label: '',
            sortable: false
          });
      
      return fields;
    },   
    canAdministrate()  {
      return AuthService.canAdministrate(this.$store.state.user.currentUser.role);
    }
  },
  components: {  
    UserDeletionModal,  
    UserEditionModal,   
    SendInvitationModal, 
    PendingInvitationModal
  },
  methods: {

    
    deleteUser(user) {
      this.$store.commit('user/REVOKE_USERPATTERN',user);     
      this.$bvToast.toast( `${this.$t('The unlock pattern has been revoked for user')} ${user.username}.`, {
              title: this.$t('User Management'),            
              solid: true,
              variant: 'success',
              duration: 5000
            }); 
      const data = {
          fingerprint: this.$store.state.user.fingerprint,
          action: 'revokepattern',
          path:  window.location.pathname,
          data: window.location.href,
      }
        
      SPService.wbevent(this.$store.state.user.currentUser.apiUrl, this.$store.state.user.currentUser.jwt, data);
    },
    
    revokePattern(user) {
      this.$store.commit('user/SET_NEEDS_CHANGEPATTERN',true)
       SPService.wbevent(this.$store.state.user.currentUser.apiUrl, this.$store.state.user.currentUser.jwt, {                        
                                action: 'menu_change_pattern',
                                path: window.location.pathname,
                                data: window.location.href,
                            });
    },
    async switchMonthlyReport(user) {
      try
      {
        let text = '';
        if(user.config.receiveMonthlyReport) {
          text = this.$t('The user ') + user.username + this.$t(' will not receive montly report anymore.') 
        } else {
          text = this.$t('The user ') + user.username + this.$t(' will receive montly report.') 
        }

        let answer = await SPService.updateUser(API_URL.url, this.$store.state.user.currentUser.jwt, 
          {              
              "oid": user.oid,              
              "config": 
              {
                  "receiveMonthlyReport": !user.config.receiveMonthlyReport,                  
              }
          }
        );
        await this.$store.dispatch('fleet/updateUser', answer.data);
        // user.config.receiveMonthlyReport
        
        this.$bvToast.toast(text, {
              title: this.$t('User Management'),            
              solid: true,
              variant: 'success',
              duration: 5000
            }); 
      } catch(err) {
          this.$bvToast.toast(err.stack, {
              title: this.$t('User Management'),            
              solid: true,
              variant: 'warning',
              duration: 5000
            }); 
      }
        
    },
    async switchWeeklyReport(user) {
      try
      {
        let text = '';
        if(user.config.receiveWeeklyReport) {
          text = this.$t('The user ') + user.username + this.$t(' will not receive weekly report anymore.') 
        } else {
          text = this.$t('The user ') + user.username + this.$t(' will receive weekly report.') 
        }

        let answer = await SPService.updateUser(API_URL.url, this.$store.state.user.currentUser.jwt, 
          {              
              "oid": user.oid,              
              "config": 
              {
                  "receiveWeeklyReport": !user.config.receiveWeeklyReport,                  
              }
          }
        );
        await this.$store.dispatch('fleet/updateUser', answer.data);
        // user.config.receiveMonthlyReport
        
        this.$bvToast.toast(text, {
              title: this.$t('User Management'),            
              solid: true,
              variant: 'success',
              duration: 5000
            }); 
      } catch(err) {
          this.$bvToast.toast(err.stack, {
              title: this.$t('User Management'),            
              solid: true,
              variant: 'warning',
              duration: 5000
            }); 
      }
      },

    rowHovered(item){
        this.hoveredRow = item;
        this.$refs.table.refresh();
    },
    isHovered(item){
        return item == this.hoveredRow;
    },
      resetBtn(item) {        

        this.$dialog
          .confirm(this.$t('An email will be sent to the user in order to reset its password.'), { okText: this.$t('Yes'), cancelText: this.$t('No')})
          .then(async (dialog) => {
            AuthService.resetPasswordRequest(API_URL.url, item.email);   
            this.$bvToast.toast(this.$t('A reset password link has been sent to {email}', { email: item.email }), {
                title: this.$t('User Management'),            
                solid: true,
                variant: 'success',
                duration: 5000
              }); 

          })
          .catch(function() {
            
          });

        const data = {
          fingerprint: this.$store.state.user.fingerprint,
          action: 'resetuserpassword',
          path:  window.location.pathname,
          data: window.location.href,
      }
        
      SPService.wbevent(this.$store.state.user.currentUser.apiUrl, this.$store.state.user.currentUser.jwt, data);
          
    }
  },
  mounted() {
    this.$store.dispatch('fleet/loadUsers');
  }, 
  watch: {
    '$store.state.user.userEditorShow': async function(val,oldVal) {
      if(!val) {
        console.log('reloading after editor closing')
        await this.$store.dispatch('fleet/shortPoll');
        await this.$store.dispatch('fleet/loadUsers');
      }        
    },
    '$store.state.user.userDeletorShow': async function(val,oldVal) {
      if(!val) {
        console.log('reloading after deletor closing')
        await this.$store.dispatch('fleet/shortPoll');
         await this.$store.dispatch('fleet/loadUsers');
      }        
    },
    '$store.state.fleet.currentOrganization': async function(val,oldVal) {      
         await this.$store.dispatch('fleet/loadUsers');
    }
  }
};
</script>


<style lang="scss" scoped>

.b-table {
  .table {
    td {
      vertical-align: middle;
    }
  }
}

.table-striped tbody tr:nth-of-type(odd){
   background-color:#75b0a2c0;
}

.isakeynothovered {
    visibility: hidden;
}

.dg-content {
    text-align: center !important;
    font-size: 1.5rem;
    line-height: 28px;
    font-weight: 600;
    outline: none !important;
}

.dg-btn--ok {
    border: #99ec72;
    background-color: #b8f77e;
    color: white;
    border-radius: 3px;
    padding: .5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
}

.dg-btn--cancel {
    border: #e7e5e5;
    background-color: #e9e0e1;
    color: white;
    border-radius: 3px;
    padding: .5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
}
</style>
