<template>
  <div class="main-content" v-if="canAdministrate">
    <UserDeletionModal/>
    <UserEditionModal/>
    <SendInvitationModal/>
    <PendingInvitationModal/>

    <div  v-if="$store.state.user.kioskable && $store.state.user.knownUsers && $store.state.user.knownUsers.length > 0">
      <b-tabs pills card class="isatab">
        <b-tab lazy key="localtab" :title="$t('Quick access on this kiosk')">              
          <UserQuickAccessManagement />
        </b-tab>
        <b-tab lazy key="isatab" :title="$t('ISA User Management')">              
          <UserManagement />
        </b-tab>        
      </b-tabs>
    </div>
   <UserManagement v-else/>
    
  
  </div>
</template>


<script>
import { SPService } from "@/services/sp.service";
import { AuthService } from "@/services/auth.service";
import UserEditionModal from "@/components/ISA/admin/UserEditionModal";
import UserDeletionModal from "@/components/ISA/admin/UserDeletionModal";
import SendInvitationModal from "@/components/ISA/admin/SendInvitationModal";
import PendingInvitationModal from "@/components/ISA/admin/PendingInvitationModal";
import UserManagement from "@/components/ISA/admin/UserManagement";
import UserQuickAccessManagement from "@/components/ISA/admin/UserQuickAccessManagement";

import { API_URL } from '@/.env';

export default {
  name: "UserManagementPage",  
    metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "ISA Users Management"
  },  
  data() {
    return {
      users: null,
        isLoading: false,
        hoveredRow: null
    };
  },
  computed: {

    fields() {
          let fields = [];
          
      fields.push({
            key: 'username',
            label: this.$t('User Name'),
            sortable: true
          });
          
      fields.push(
          {
            key: 'email',
            label: this.$t('Email'),
            sortable: true
          });
          
      fields.push({
            key: 'role',
            label: this.$t('Role'),
            sortable: true
          });

          // if(!this.$store.state.user.isMobile) fields.push({ key: 'edit', label: this.$t('Edit User') });
          // if(!this.$store.state.user.isMobile) fields.push({ key: 'revoke', label: this.$t('Delete') });
          // if(!this.$store.state.user.isMobile) fields.push({ key: 'mailreportMonthly', label: this.$t('Receives Montly Reports') });
          // if(!this.$store.state.user.isMobile) fields.push({ key: 'mailreportWeekly', label: this.$t('Receives Weekly Reports') });
          // if(!this.$store.state.user.isMobile) fields.push({ key: 'lockpattern', label: this.$t('Unock Pattern') });
          // if(!this.$store.state.user.isMobile) fields.push({ key: 'rights', label: this.$t('Access Rights') });
      
     /* for(let rs of this.$store.state.fleet.roomsetList)
      {      
        if(rs.organizationLabel !== this.$store.state.fleet.currentOrganization) continue;
        fields.push({ key: 'rightsOn' + rs.oid, label: 'Rights on ' + rs.label, sortable: true})
       }   
      */
      return fields;
    },   
    canAdministrate()  {
      return AuthService.canAdministrate(this.$store.state.user.currentUser.role);
    }
  },
  components: {  
    UserDeletionModal,  
    UserEditionModal,   
    SendInvitationModal, 
    PendingInvitationModal,
    UserManagement,
    UserQuickAccessManagement
  },
  methods: {
    gomycell(key) {
      return `cell(rightsOn${key})`; // simple string interpolation
    },
    rightmycell(key) {
      return `data.item.rightsOn${key}`; // simple string interpolation
    },
    idmycell(u,key) {
      return `${u}_${key}`; // simple string interpolation
    },
    hasRightsOn(user, rs) {
      return rs.authorizedUserOIDList.find(a => a === user.oid) != null      
    },
    
    async setRightsOn(user, rs, ev) {
      let result = null;
      let text = user.username + ' ';
      if(ev.target.checked) { // gives right
        text += this.$t('has now access to care unit ')
        result = await SPService.addUserToRoomset(API_URL.url, this.$store.state.user.currentUser.jwt, user.oid, rs);
      } else { // removes rights
      text += this.$t('has no more access to care unit ')
        result = await SPService.removeUserFromRoomset(API_URL.url, this.$store.state.user.currentUser.jwt, user.oid, rs);
      }
      text += ' ' + rs.label;
      await this.$store.dispatch('fleet/updateRoomsetAuthorizedList',result.data);
      this.$bvToast.toast(text, {
              title: this.$t('User Management'),            
              solid: true,
              variant: 'success',
              duration: 5000
            }); 
      const data = {
          fingerprint: this.$store.state.user.fingerprint,
          action: 'usereditrights',
          path:  window.location.pathname,
          data: window.location.href,
      }
        
      SPService.wbevent(this.$store.state.user.currentUser.apiUrl, this.$store.state.user.currentUser.jwt, data);
      
    },

    createUser() {      
      let user = { create: true}
       this.$store.commit('user/SHOW_USEREDITOR',user);     
    },
    createInvitation() {      
      let user = { create: true}
      this.$store.commit('user/SHOW_SENDINVITATION',user);
            const data = {
          fingerprint: this.$store.state.user.fingerprint,
          action: 'userinvite',
          path:  window.location.pathname,
          data: window.location.href,
      }
        
      SPService.wbevent(this.$store.state.user.currentUser.apiUrl, this.$store.state.user.currentUser.jwt, data);
    },
    editUser(user) {      
      console.log(user)
       this.$store.commit('user/SHOW_USEREDITOR',user);      
             const data = {
          fingerprint: this.$store.state.user.fingerprint,
          action: 'useredit',
          path:  window.location.pathname,
          data: window.location.href,
      }
        
      SPService.wbevent(this.$store.state.user.currentUser.apiUrl, this.$store.state.user.currentUser.jwt, data);
    },
    deleteUser(user) {
       this.$store.commit('user/SHOW_USERDELETOR',user);      
             const data = {
          fingerprint: this.$store.state.user.fingerprint,
          action: 'userdelete',
          path:  window.location.pathname,
          data: window.location.href,
      }
        
      SPService.wbevent(this.$store.state.user.currentUser.apiUrl, this.$store.state.user.currentUser.jwt, data);
    },

    openPendingInvitations() {
      this.$store.commit('user/SHOW_PENDINGINVITATION',true);      
    },

    revokePattern(user) {
      this.$store.commit('user/REVOKE_USERPATTERN',user);     
      this.$bvToast.toast( `${this.$t('The unlock pattern has been revoked for user')} ${user.username}.`, {
              title: this.$t('User Management'),            
              solid: true,
              variant: 'success',
              duration: 5000
            }); 
      const data = {
          fingerprint: this.$store.state.user.fingerprint,
          action: 'revokepattern',
          path:  window.location.pathname,
          data: window.location.href,
      }
        
      SPService.wbevent(this.$store.state.user.currentUser.apiUrl, this.$store.state.user.currentUser.jwt, data);
    },
    async switchMonthlyReport(user) {
      try
      {
        let text = '';
        if(user.config.receiveMonthlyReport) {
          text = this.$t('The user ') + user.username + this.$t(' will not receive montly report anymore.') 
        } else {
          text = this.$t('The user ') + user.username + this.$t(' will receive montly report.') 
        }

        let answer = await SPService.updateUser(API_URL.url, this.$store.state.user.currentUser.jwt, 
          {              
              "oid": user.oid,              
              "config": 
              {
                  "receiveMonthlyReport": !user.config.receiveMonthlyReport,                  
              }
          }
        );
        await this.$store.dispatch('fleet/updateUser', answer.data);
        // user.config.receiveMonthlyReport
        
        this.$bvToast.toast(text, {
              title: this.$t('User Management'),            
              solid: true,
              variant: 'success',
              duration: 5000
            }); 
      } catch(err) {
          this.$bvToast.toast(err.stack, {
              title: this.$t('User Management'),            
              solid: true,
              variant: 'warning',
              duration: 5000
            }); 
      }
        
    },
    async switchWeeklyReport(user) {
      try
      {
        let text = '';
        if(user.config.receiveWeeklyReport) {
          text = this.$t('The user ') + user.username + this.$t(' will not receive weekly report anymore.') 
        } else {
          text = this.$t('The user ') + user.username + this.$t(' will receive weekly report.') 
        }

        let answer = await SPService.updateUser(API_URL.url, this.$store.state.user.currentUser.jwt, 
          {              
              "oid": user.oid,              
              "config": 
              {
                  "receiveWeeklyReport": !user.config.receiveWeeklyReport,                  
              }
          }
        );
        await this.$store.dispatch('fleet/updateUser', answer.data);
        // user.config.receiveMonthlyReport
        
        this.$bvToast.toast(text, {
              title: this.$t('User Management'),            
              solid: true,
              variant: 'success',
              duration: 5000
            }); 
      } catch(err) {
          this.$bvToast.toast(err.stack, {
              title: this.$t('User Management'),            
              solid: true,
              variant: 'warning',
              duration: 5000
            }); 
      }
      },

    rowHovered(item){
        this.hoveredRow = item;
        this.$refs.table.refresh();
    },
    isHovered(item){
        return item == this.hoveredRow;
    },
      resetBtn(item) {        

        this.$dialog
          .confirm(this.$t('An email will be sent to the user in order to reset its password.'), { okText: this.$t('Yes'), cancelText: this.$t('No')})
          .then(async (dialog) => {
            AuthService.resetPasswordRequest(API_URL.url, item.email);   
            this.$bvToast.toast(this.$t('A reset password link has been sent to {email}', { email: item.email }), {
                title: this.$t('User Management'),            
                solid: true,
                variant: 'success',
                duration: 5000
              }); 

          })
          .catch(function() {
            
          });

        const data = {
          fingerprint: this.$store.state.user.fingerprint,
          action: 'resetuserpassword',
          path:  window.location.pathname,
          data: window.location.href,
      }
        
      SPService.wbevent(this.$store.state.user.currentUser.apiUrl, this.$store.state.user.currentUser.jwt, data);
          
    }
  },
  mounted() {
    
  }, 
  watch: {

  }
};
</script>

<style lang="scss" scoped>
.b-table {
  .table {
    td {
      vertical-align: middle;
    }
  }
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: #75b0a2c0;
}

.apexcharts-yaxis-label {
  font-size: 1rem;
}

.isatab {
  padding-bottom: 0px;
  padding-top: 0px
}
</style>