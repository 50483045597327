<template>
  <b-modal hide-footer v-model="showUserEditor" @hidden="close" :title="$t('ISA User')">
    <div>
      <b-card>
        <validation-observer ref="observer" v-slot="{ handleSubmit }" >
            <b-form @submit.prevent="handleSubmit(apply)">
                <validation-provider :name="$t('Email')" :rules="{ required: true, regex: /^[^@]+@[^@]+\.[a-z]{2,}(_[a-z]+)*$/i }" v-slot="validationContext">
                    <b-form-group label-cols-sm="3"
                                  :label="$t('Email')"
                                  label-align-sm="left"
                                  label-for="email">
                        <b-form-input v-model="email"
                                      :state="getValidationState(validationContext)"
                                      aria-describedby="input-3-live-feedback"></b-form-input>
                        <b-form-invalid-feedback id="input-3-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <b-btn variant="warning" v-if="!create && password == null" align-h="center" @click="resetPassword" style="margin-left: 110px">{{ $t('Change Password')}}</b-btn>
                <validation-provider v-else :name="$t('Password')" :rules="{ required: true, min: 5, max: 250 }" v-slot="validationContext">
                    <b-form-group label-cols-sm="3" :label="$t('Password')" label-align-sm="left" label-for="Password">
                        <b-input-group>
                            <b-form-input v-model="password"
                                          :state="getValidationState(validationContext)"
                                          aria-describedby="input-5-live-feedback"
                                          :type="showPassword ? 'text' : 'password'">
                            </b-form-input>
                            <b-input-group-append>
                                <b-btn slot="append" class="btn-primary" @click="showPassword = !showPassword" style="margin-left: 2px"><font-awesome-icon icon="eye" class="icon alt" /></b-btn>
                            </b-input-group-append>
                            <b-input-group-append>
                                <b-btn slot="append" class="btn-primary" @click="generatePassword">{{ $t('Generate') }}</b-btn>
                            </b-input-group-append>
                        </b-input-group>

                        <span class="invalid-feedback" style="display: block">{{ validationContext.errors[0] }}</span>

                    </b-form-group>
                </validation-provider>
                <div v-if="email && changingPassword">                    
                    <p-check class="pretty p-switch" color="success" v-model="sendMailToUser">
                        {{ $t('Send a One Time Password by email to {email}', { email }) }}
                    </p-check>

                </div>

                <hr />
                <b-form-group v-if="role !== 0"
                              label-cols-sm="3"
                              :label="$t('Firstname:')"
                              label-align-sm="left"
                              label-for="firstname">
                    <b-form-input v-model="firstName"></b-form-input>
                </b-form-group>

                <b-form-group v-if="role !== 0"
                              label-cols-sm="3"
                              :label="$t('Lastname:')"
                              label-align-sm="left"
                              label-for="lastname">
                    <b-form-input v-model="lastName"></b-form-input>
                </b-form-group>

                <hr />
                <validation-provider :name="$t('Role')" :rules="{ required: !role || role === 0 || role === 3 || role === 4 || role === 11 }" v-slot="validationContext">
                    <b-form-group label-cols-sm="3" :label="$t('Role')" label-align-sm="left" label-for="role">
                        <b-form-select v-if="!role || role === 0 || role === 3 || role === 4 || role === 11"
                                       v-model="role"
                                       :options="roles"
                                       :state="getValidationState(validationContext)"
                                       aria-describedby="input-2-live-feedback"></b-form-select>
                        <b-form-text v-else>mintt system user {{role}}</b-form-text>
                        <b-form-invalid-feedback id="input-2-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <validation-provider :name="$t('language')" :rules="{ required: true}" v-slot="validationContext">
                    <b-form-group label-cols-sm="3" :label="$t('Language')" label-align-sm="left" label-for="Language">
                        <b-form-select v-model="preferredLanguage"
                                       :options="preferredLanguages"
                                       :state="getValidationState(validationContext)"
                                       aria-describedby="input-p2-live-feedback"></b-form-select>
                        <b-form-invalid-feedback id="input-p2-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <b-form-group label-cols-sm="3"
                              :label="$t('Receive montly reports')"
                              label-align-sm="left"
                              label-for="receiveMonthlyReport">
                    <label class="switch switch-primary mr-3" v-if="email">
                        <input type="checkbox" v-model="receiveMonthlyReport" />
                        <span class="slider"></span>
                    </label>
                </b-form-group>

                <b-form-group label-cols-sm="3"
                              :label="$t('Receive weekly reports')"
                              label-align-sm="left"
                              label-for="firstname">
                    <label class="switch switch-primary mr-3" v-if="email">
                        <input type="checkbox" v-model="receiveWeeklyReport" />
                        <span class="slider"></span>
                    </label>
                </b-form-group>

                <hr />
                <h6>{{ $t('Access Rights') }}:</h6>
                <b-form-group id="user_access_right">
                  <b-form-group
                        v-for="acc in accesses"
                        :key="acc.oid"
                        label-cols-sm="3"
                        :label="acc.label"
                        label-align-sm="left"
                        :label-for="acc.label">

                      <label class="switch switch-primary mr-3" >
                        <input type="checkbox" v-model="acc.value" />
                        <span class="slider"></span>
                      </label>
                  </b-form-group>
                </b-form-group>
            




                <!-- <p-check v-if="$store.state.user.currentUser.email && role !== 0" class="pretty p-switch p-fill" color="success"  v-model="sendMailToAdmin">
        Send password to you {{sendMailToAdmin ? '(' + $store.state.user.currentUser.email + ')' : ''}}
    </p-check>
                <p v-else>Please define your own email address if you want to receive the password</p>
                    -->

                <hr />
                <b-button class="float-left" @click="close">
                    {{ $t('Cancel')}}
                </b-button>
                <b-button :loading="requesting" variant="primary" class="float-right" type="submit">
                    {{ $t('Apply')}}
                </b-button>


            </b-form>
        </validation-observer>
      </b-card>
    </div>
  </b-modal>
</template>

<script>
import { AuthService } from "@/services/auth.service";
import { API_URL } from '@/.env'
import { SPService } from "@/services/sp.service";
import ROLES from "@/constants/roles";
import Multiselect from "vue-multiselect";
var generator = require('generate-password');
 


export default {
  components: {    
    Multiselect,    
  },
  data() {
    return {
      requesting: false,
      showUserEditor: false,
      oid: null,
      create: false,
      username: null,
      password: null,
      showPassword: false,
      changingPassword: false,
      firstName: null,
      lastName: null,

      email: null,
      role: null,
      roles: [
        { value: ROLES.kiosk, text: this.$t("Kiosk") },
        { value: ROLES.doctor, text: this.$t("Caregiver") },
        { value: ROLES.admin, text: this.$t("Admin") },
        { value: ROLES.medAdmin, text: this.$t("Medical Admin") }
      ],
      phone: null,
      isCall: null,
      notification: null,
      notifications: [
        { value: "0", text: this.$t('"None"') },
        { value: "1", text: this.$t("Phone Call") },
        { value: "2", text: this.$t("SMS") }
      ],
      enableBedExitNotification: null,
      enableFallNotification: null,
      preferredLanguage: null,
      phoneCountries: ["BE", "FR", "DE", "US", "SW", "LU"],
      accesses: [],
      sendMailToUser: false,
      sendMailToAdmin: false,
      receiveMonthlyReport: false,
      receiveWeeklyReport: false,
    };
  },
  computed: {
    options() {
      return this.$store.state.fleet.roomsetList.filter(r => r.organizationLabel === this.$store.state.fleet.currentOrganization && r.label.charAt(0) !== '_').map(p => {
        return { name: p.label };
      });
    },
    preferredLanguages() {
      return[
        //{ value: "zh", text: "Chinese" },
        { value: "nl", text: this.$t("Dutch") },
        { value: "en", text: this.$t("English") },
        { value: "fr", text: this.$t("French") },
        //{ value: "de", text: "German" },
        //{ value: "it", text: "Italian" },
        //{ value: "ja", text: "Japanese" },
        //{ value: "pt", text: "Portugese" },
        //{ value: "ru", text: "Russian" },
        //{ value: "sp", text: "Spanish" }
      ]
    }
  },
  watch: {
    "$store.state.user.userEditorShow": function(val) {
      this.showUserEditor = val;
          this.loadUser();
          this.changingPassword = false;
        this.showPassword = false;
    },
    "$store.state.user.userEditorData": function(user) {
        this.loadUser();
        this.changingPassword = false;
        this.showPassword = false;
    }
  },
  methods: {
    validateAccesses() {
      if(this.roles !== 5) {
        return { required: true}
      } else {
        return {}
      }
    },
    generatePassword() {
       this.password = generator.generate({
          length: 6,
          numbers: true
       });        
       this.changingPassword = true;
    },
    resetPassword() {
      this.password = generator.generate({
          length: 6,
          numbers: true
      });      
        this.changingPassword = true;
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    close() {
      this.$store.commit("user/SHOW_USEREDITOR", null);
    },
    loadUser() {
      if (this.$store.state.user.userEditorData) {
        this.requesting = false;
        this.create = this.$store.state.user.userEditorData.create;
        this.oid = this.$store.state.user.userEditorData.oid;
        this.username = this.$store.state.user.userEditorData.username;
        this.password = null;
        this.role = this.$store.state.user.userEditorData.role;
        this.phone = this.$store.state.user.userEditorData.phone;
        this.isCall = this.$store.state.user.userEditorData.isCall;
        this.firstName = this.$store.state.user.userEditorData.firstName;
        this.lastName = this.$store.state.user.userEditorData.lastName;
        this.email = this.$store.state.user.userEditorData.email;
        this.preferredLanguage = this.$store.state.user.userEditorData.config.preferredLanguage;
        this.enableFallNotification = this.$store.state.user.userEditorData.config.enableFallNotification;
        this.enableBedExitNotification = this.$store.state.user.userEditorData.config.enableBedExitNotification;
        this.receiveMonthlyReport = this.$store.state.user.userEditorData.config.receiveMonthlyReport;
        this.receiveWeeklyReport = this.$store.state.user.userEditorData.config.receiveWeeklyReport;
        this.accesses = [];
        for (let rs of this.$store.state.fleet.roomsetList.filter(f => f.organizationLabel === this.$store.state.fleet.currentOrganization  && f.label.charAt(0) !== '_')) {
          this.accesses.push({ label: rs.label, oid: rs.oid, value:  rs.authorizedUserOIDList.find(u => u === this.oid) != null});          
        }        
      }
    },
    validatePhone({ number, isValid, country }) {
      console.log(number);
      console.log(isValid);
      console.log(country);
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000)
      };
      this.value.push(tag);
      console.log(this.value);
    },
    async apply() {
      // here we know that the form is validated
      this.requesting = true
  
      if(this.create) { // here we create a new user
        // get first roomsetOID
        let frs = this.accesses[0];
        let payload = {              
              "username": this.email,
              "password": this.password,
              "email": this.email,
              "phone": '',
              "isCall": false,
              "firstName": this.firstName || "",
              "lastName": this.lastName|| "",
              "role": this.role,
              "needNewPassword": true,
              "organizationLabel": this.$store.state.fleet.currentOrganization,
              "roomsetOID": frs.oid,
              "config": 
              {
                  "preferredLanguage": this.preferredLanguage,
                  "enableFallNotification": false,
                  "enableBedExitNotification": false,
                  "receiveWeeklyReport": this.receiveWeeklyReport,
                  "receiveMonthlyReport": this.receiveMonthlyReport,

              }
          } ;       
        let answer = await SPService.createUser(API_URL.url, this.$store.state.user.currentUser.jwt, payload);
        if(answer.status !==  200) {
          this.$store.commit('toast/NEW', { type: 'error', message: `Error creating user ${this.username}.\n${answer.status}: ${answer.message}`})  
        }
        else
          this.$store.commit('toast/NEW', { type: 'success', message: `User ${this.username} created with success`})  
        this.oid = answer.data.oid;
        console.log('got oid ' + this.oid);

        try
        {
          payload.oid = this.oid;
          let answer = await SPService.updateUser(API_URL.url, this.$store.state.user.currentUser.jwt, payload);      
        } catch(err) {

        }
      }
      else {
        
        let answer = await SPService.updateUser(API_URL.url, this.$store.state.user.currentUser.jwt, 
          {              
              "oid": this.oid,
              "username": this.email,
              "password": this.password,
              "email": this.email,
              "phone": this.phone,
              "isCall": this.isCall,
              "firstName": this.firstName || "",
              "lastName": this.lastName || "",
              "role": this.role,
              "needNewPassword": true,
              // "organizationLabel": we don't touch this
              "config": 
              {
                  "preferredLanguage": this.preferredLanguage,
                  "enableFallNotification": this.enableFallNotification,
                  "enableBedExitNotification": this.enableBedExitNotification,
                  "receiveWeeklyReport": this.receiveWeeklyReport,
                  "receiveMonthlyReport": this.receiveMonthlyReport,
              },
              sendMailToUser: this.sendMailToUser,
          }
        );
        await this.$store.dispatch('fleet/updateUser', answer.data);
      }
      // handle user rights

      // ensure user has all its accesses
      console.log('checking accesses')
      for(const acc of this.accesses)
      {
        let rs = this.$store.state.fleet.roomsetList.find(p => p.oid === acc.oid);
        if(rs) {
            if(acc.value && rs.authorizedUserOIDList.find(u => u === this.oid) == null) {
              // needs to add user rights              
              await SPService.addUserToRoomset(API_URL.url, this.$store.state.user.currentUser.jwt, this.oid, rs);              
              this.$bvToast.toast( this.$t('Access to {roomset} given to {username}',{ roomset: rs.label, username: this.username}), {
                  title: this.$t('User Right Mangagement'),            
                  solid: true,
                  variant: 'success',
                  duration: 5000
                });
            } else if(!acc.value && rs.authorizedUserOIDList.find(u => u === this.oid) != null){                
                await SPService.removeUserFromRoomset(API_URL.url, this.$store.state.user.currentUser.jwt, this.oid, rs);
                this.$bvToast.toast( this.$t('Access to {roomset} removed to {username}',{ roomset: rs.label, username: this.username}), {
                  title: this.$t('User Right Mangagement'),            
                  solid: true,
                  variant: 'success',
                  duration: 5000
                });
            }
        }
        else {
          console.log('rs not found ')          
          console.log(this.$store.state.fleet.roomsetList);
        }
      }

      this.requesting = false
      // close editor
      this.$store.commit("user/SHOW_USEREDITOR", null);
    }
  },
  mounted() {
      this.requesting = false;
      this.changingPassword = false;
      this.showPassword = false;
      this.$store.commit("user/SHOW_USEREDITOR", null);      
  },
  name: "UserEditionModal"
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
</style>
