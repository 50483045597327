<template>
  
    <b-modal
      hide-footer
      v-model="showUserDeletor"
      @hidden="close"
      :title="$t('Delete user')">      
      <b-card >
        <h3>{{ $t('Confirmation before deletion') }}</h3>
        <p class="text-justify">
            {{ $t('This operation is irreversible.') }}
        </p>
        <p>{{$t('Please enter the username ') }} <i>{{$store.state.user.userDeletorData ? $store.state.user.userDeletorData.username : ''}}</i> {{ $t(' to confirm the deletion') }}.</p>
        <validation-observer ref="observer" v-slot="{ handleSubmit }" >
          <b-form @submit.stop.prevent="handleSubmit(apply)">
            <validation-provider
              :name="$t('Confirmation')"
              :rules="{ required: true, is:$store.state.user.userDeletorData ? $store.state.user.userDeletorData.username : 'mlkjmlkj' }"
              v-slot="validationContext"
            >
              <b-form-group
                label-cols-sm="4"
                :label="$t('Username:')"
                label-align-sm="left"
                label-for="username"
              >
                <b-form-input
                  v-model="username"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-1-live-feedback"
                ></b-form-input>
                <b-form-invalid-feedback
                  id="input-1-live-feedback"
                >{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

                        
                <b-button  class="float-left" @click="close">
                    {{ $t('Cancel')}}
                </b-button>
                <b-button  :loading="requesting" variant="danger" class="float-right" type="submit">
                    {{ $t('Delete')}}
                </b-button>
            

          </b-form>
        </validation-observer>
      </b-card>
    </b-modal>  
</template>

<script>
import { AuthService } from '@/services/auth.service'
import { SPService } from "@/services/sp.service";
import { API_URL } from '@/.env'
export default  {
  components: {
    
  },  
  data() {
    return {
      showUserDeletor: false     ,
      requesting: false, 
      username: null,
    }
  },
  watch : {
    '$store.state.user.userDeletorShow': function(val) {       
        console.log('yay help') 
        this.showUserDeletor = val     
        this.requesting = false
    }
  },    
  methods: {
        close() {           
            this.$store.commit('user/SHOW_USERDELETOR',null);                        
        },
        getValidationState({ dirty, validated, valid = null }) {
          return dirty || validated ? valid : null;
        },
        async apply() {
          this.requesting = true
          let answer = await SPService.deleteUser(API_URL.url, this.$store.state.user.currentUser.jwt, this.$store.state.user.userDeletorData.oid)
          if(answer.status !== 200)
            this.$store.commit('toast/NEW',{ status: 'error', message: `Error deleting user ${this.$store.state.user.userDeletorData.username}`})
          else 
            this.$store.commit('toast/NEW',{ status: 'success', message: `User ${this.$store.state.user.userDeletorData ? this.$store.state.user.userDeletorData.username : ''} is now deleted.`})
          this.requesting = false
          this.$store.commit('user/SHOW_USERDELETOR',null);
        }
    },
  mounted() {      
     this.username = '';
      this.$store.commit('user/SHOW_USERDELETOR',null);
  },
  name: "UserDeletionModal"
}
</script>

<style lang="scss" scoped>

</style>
