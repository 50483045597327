<template>
  <div class="main-content" v-if="canAdministrate">
    
    <h1 class="mb-3 text-26" id="usermanagement_tittle">{{ $t("Users Management") }}</h1>    
    <div class="d-flex flex-row">
      <div class="p-0">
        <b-btn @click="createUser" variant="primary" v-if="false">
          <font-awesome-icon icon="plus"  />
          {{ $t('Create User') }}
          </b-btn>        
             
      </div>              
      <div class="p-0">
       <b-btn @click="createInvitation" variant="primary" id="send_invitation_button">
          <font-awesome-icon icon="plus"  />
          {{ $t('Send Invitation') }}
          </b-btn>     
      </div>

      <div class="p-0" v-if="$store.state.fleet.invitations && $store.state.fleet.invitations.length" style="margin-left: 1rem;">
          <a href="#"  v-if="$store.state.fleet.invitations && $store.state.fleet.invitations.length === 1" @click="openPendingInvitations()">{{ $t('1 invitation pending') }}</a>
          <a href="#" v-if="$store.state.fleet.invitations && $store.state.fleet.invitations.length > 1" @click="openPendingInvitations()">{{ $store.state.fleet.invitations.length + ' ' + $t('invitations pending') }}</a>
      </div>
    </div>
    <b-row style="height: 100%;overflow:hidden" id="usermanagement_table">
    <b-table 
             ref="table"
             :busy="isLoading" 
             hover 
             sm 
             borderless                            
             responsive
             light 
             class="w-auto" 
             style="margin-bottom: 4rem;"
             :items="$store.state.fleet.users ? $store.state.fleet.users.filter(u => u.isNotificationUser === false && u.organizationLabel === this.$store.state.fleet.currentOrganization) : []" 
             :fields="fields" 
             @row-hovered="rowHovered"
             @row-clicked="rowHovered"
             >      
      <template v-slot:table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>{{ $t('Loading...') }}</strong>
        </div>
        </template>

      <template v-slot:cell(email)="row">
        <div class="d-flex flex-row">
              <div class="mr-auto p-2">
                {{ row.item.email || row.item.username }}
              </div>
        </div>              
        
      </template>

      <template v-slot:cell(username)="row">
          <div class="d-flex flex-row">
              <div class="mr-auto p-2">{{ (row.item.firstName && row.item.lastName) ? (row.item.firstName + ' ' + row.item.lastName) : row.item.username }}</div>
              <div class="p-2">
                    <a @click="resetBtn(row.item)"  :class="!isHovered(row.item) ? 'isakeynothovered' :''"  style="cursor: pointer" ><font-awesome-icon icon="key"/> <small>{{ $t('Reset Password') }}</small></a></div>

              <b-dropdown  size="lg" dropleft  variant="link" toggle-class="text-decoration-none" no-caret class="p-2" >
                  <template #button-content>
                      <font-awesome-icon icon="ellipsis-v" />
                  </template>
                  
                  <b-dropdown-item class="p-2" v-if="$store.state.user.knownUsers && $store.state.user.knownUsers.find(p => p.oid === row.item.oid) != null"  @click="revokePattern(row.item)"> <font-awesome-icon icon="trash-alt" /> {{ $t('Revoke Unlock pattern') }}</b-dropdown-item>
                  <b-dropdown-item class="p-2" @click="editUser(row.item)"> <font-awesome-icon icon="wrench" />{{ $t('Edit...') }}</b-dropdown-item>
                  <b-dropdown-item class="p-2" @click="deleteUser(row.item)"> <font-awesome-icon icon="user-slash" />{{ $t('Delete User') }}</b-dropdown-item>                  
              </b-dropdown>
              
          </div>
</template>

      <template v-slot:cell(edit)="row">
        <b-button size="sm" variant="primary" class="btnedit left"  @click="editUser(row.item)" >
          <font-awesome-icon icon="edit" />
          {{ $t('Edit') }}
        </b-button>
      </template>
      <template v-slot:cell(revoke)="row">
        <b-button variant="light" size="sm" right @click="deleteUser(row.item)" class="btndelete right">
          <font-awesome-icon icon="trash-alt"  />
          {{ $t('Delete') }}
        </b-button>
      </template>
      <template v-slot:cell(lockpattern)="row">
        <b-button v-if="$store.state.user.knownUsers && $store.state.user.knownUsers.find(p => p.oid === row.item.oid) != null" variant="info" size="sm" right @click="revokePattern(row.item)" class="btndelete right">
          <font-awesome-icon icon="trash-alt"  />
          {{ $t('Revoke Pattern') }}
        </b-button>
      </template>

      <template v-slot:cell(mailreportMonthly)="row">
         <label class="switch switch-primary mr-3" v-if="row.item.email">
            <input type="checkbox" :checked="row.item.config.receiveMonthlyReport" @change="switchMonthlyReport(row.item)" />
            <span class="slider"></span>
          </label>
      </template>

      <template v-slot:cell(mailreportWeekly)="row">
        <label class="switch switch-primary mr-3" v-if="row.item.email">
            <input type="checkbox" :checked="row.item.config.receiveWeeklyReport" @change="switchWeeklyReport(row.item)" />
            <span class="slider"></span>
        </label>        
      </template>
      
      <template v-slot:cell(rights)="row">
        <div class="row" v-if="row.item.role !== 5 && row.item.role !== 0">
          
          <div v-for="rs of $store.state.fleet.roomsetList.filter(rs => rs.organizationLabel === $store.state.fleet.currentOrganization && rs.label.charAt(0) !== '_')" :key="rs.oid"  class="col-md-6 mb-30">            
            <div class="row">
              <div class="col-md-12"><span>{{ rs.label }}</span>                 </div>
              <div class="col-md-12">
                <label class="switch switch-primary mr-3">
                <input type="checkbox" :checked="hasRightsOn(row.item, rs)" @change="setRightsOn(row.item, rs, $event)"  />              
                <span class="slider"></span>
                </label>   
              </div>              
              
            </div>
            
            
          </div>          
        </div>
      </template>
                    
      <template v-slot:cell(role)="row">
         <div class="d-flex flex-row">
              <div class="mr-auto p-2">
                {{row.item.role === 0 ? $t('Kiosk') : ''}}
                {{row.item.role === 1 ? $t('Security') : ''}}
                {{row.item.role === 2 ? $t('Nurse') : ''}}
                {{row.item.role === 3 ? $t('Caregiver') : ''}}
                {{row.item.role === 4 ? $t('Administrator') : ''}}
                {{row.item.role === 5 ? $t('Mintt Administrator') : ''}}
                {{row.item.role === 6 ? $t('Worker') : ''}}
                {{row.item.role === 7 ? $t('Sensor') : ''}}
                {{row.item.role === 8 ? $t('Electrician') : ''}}
                {{row.item.role === 9 ? $t('API') : ''}}
                {{row.item.role === 10 ? $t('Moderator') : ''}}
                {{row.item.role === 11 ? $t('Medical Admin') : ''}}
              </div>
        </div>      
        
      </template>
    </b-table>
    </b-row>
  </div>
  <div class="main-content" v-else>
    <b-row>
      <b-col>
        <div class="not-found-wrap text-center">
          <h1 class="text-25">
            {{ $t('Access Denied') }}
          </h1>
          <p class="text-36 subheading mb-3">{{ $t("You don't have enough privileges to administrate users.")}}</p>
          <p class="mb-5 text-muted text-18">
            {{ $t('You need administration role, or medical administrator role to access this page. If you think that you should access to this page, please contact your administrator or mintt support at ') }} <a href="mailto:support@mintt.care">support@mintt.care</a>
          </p>
          <a class="btn btn-lg btn-primary btn-rounded" href="/" id="gotohome">{{ $t('Go back to home') }}</a
          >
        </div>
      </b-col>
    </b-row>    
  </div>
</template>


<script>
import { SPService } from "@/services/sp.service";
import { AuthService } from "@/services/auth.service";
import UserEditionModal from "@/components/ISA/admin/UserEditionModal";
import UserDeletionModal from "@/components/ISA/admin/UserDeletionModal";
import SendInvitationModal from "@/components/ISA/admin/SendInvitationModal";
import PendingInvitationModal from "@/components/ISA/admin/PendingInvitationModal";
import { API_URL } from '@/.env';

export default {
  name: "UserManagement",  
    metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "ISA Users Management"
  },  
  data() {
    return {
      users: null,
        isLoading: false,
        hoveredRow: null
    };
  },
  computed: {

    fields() {
          let fields = [];
          
      fields.push({
            key: 'username',
            label: this.$t('User Name'),
            sortable: true
          });
          
      fields.push(
          {
            key: 'email',
            label: this.$t('Email'),
            sortable: true
          });
          
      fields.push({
            key: 'role',
            label: this.$t('Role'),
            sortable: true
          });

          // if(!this.$store.state.user.isMobile) fields.push({ key: 'edit', label: this.$t('Edit User') });
          // if(!this.$store.state.user.isMobile) fields.push({ key: 'revoke', label: this.$t('Delete') });
          // if(!this.$store.state.user.isMobile) fields.push({ key: 'mailreportMonthly', label: this.$t('Receives Montly Reports') });
          // if(!this.$store.state.user.isMobile) fields.push({ key: 'mailreportWeekly', label: this.$t('Receives Weekly Reports') });
          // if(!this.$store.state.user.isMobile) fields.push({ key: 'lockpattern', label: this.$t('Unock Pattern') });
          // if(!this.$store.state.user.isMobile) fields.push({ key: 'rights', label: this.$t('Access Rights') });
      
     /* for(let rs of this.$store.state.fleet.roomsetList)
      {      
        if(rs.organizationLabel !== this.$store.state.fleet.currentOrganization) continue;
        fields.push({ key: 'rightsOn' + rs.oid, label: 'Rights on ' + rs.label, sortable: true})
       }   
      */
      return fields;
    },   
    canAdministrate()  {
      return AuthService.canAdministrate(this.$store.state.user.currentUser.role);
    }
  },
  components: {  
    UserDeletionModal,  
    UserEditionModal,   
    SendInvitationModal, 
    PendingInvitationModal
  },
  methods: {
    gomycell(key) {
      return `cell(rightsOn${key})`; // simple string interpolation
    },
    rightmycell(key) {
      return `data.item.rightsOn${key}`; // simple string interpolation
    },
    idmycell(u,key) {
      return `${u}_${key}`; // simple string interpolation
    },
    hasRightsOn(user, rs) {
      return rs.authorizedUserOIDList.find(a => a === user.oid) != null      
    },
    
    async setRightsOn(user, rs, ev) {
      let result = null;
      let text = user.username + ' ';
      if(ev.target.checked) { // gives right
        text += this.$t('has now access to care unit ')
        result = await SPService.addUserToRoomset(API_URL.url, this.$store.state.user.currentUser.jwt, user.oid, rs);
      } else { // removes rights
      text += this.$t('has no more access to care unit ')
        result = await SPService.removeUserFromRoomset(API_URL.url, this.$store.state.user.currentUser.jwt, user.oid, rs);
      }
      text += ' ' + rs.label;
      await this.$store.dispatch('fleet/updateRoomsetAuthorizedList',result.data);
      this.$bvToast.toast(text, {
              title: this.$t('User Management'),            
              solid: true,
              variant: 'success',
              duration: 5000
            }); 
      const data = {
          fingerprint: this.$store.state.user.fingerprint,
          action: 'usereditrights',
          path:  window.location.pathname,
          data: window.location.href,
      }
        
      SPService.wbevent(this.$store.state.user.currentUser.apiUrl, this.$store.state.user.currentUser.jwt, data);
      
    },

    createUser() {      
      let user = { create: true}
       this.$store.commit('user/SHOW_USEREDITOR',user);     
    },
    createInvitation() {      
      let user = { create: true}
      this.$store.commit('user/SHOW_SENDINVITATION',user);
            const data = {
          fingerprint: this.$store.state.user.fingerprint,
          action: 'userinvite',
          path:  window.location.pathname,
          data: window.location.href,
      }
        
      SPService.wbevent(this.$store.state.user.currentUser.apiUrl, this.$store.state.user.currentUser.jwt, data);
    },
    editUser(user) {      
      console.log(user)
       this.$store.commit('user/SHOW_USEREDITOR',user);      
             const data = {
          fingerprint: this.$store.state.user.fingerprint,
          action: 'useredit',
          path:  window.location.pathname,
          data: window.location.href,
      }
        
      SPService.wbevent(this.$store.state.user.currentUser.apiUrl, this.$store.state.user.currentUser.jwt, data);
    },
    deleteUser(user) {
       this.$store.commit('user/SHOW_USERDELETOR',user);      
             const data = {
          fingerprint: this.$store.state.user.fingerprint,
          action: 'userdelete',
          path:  window.location.pathname,
          data: window.location.href,
      }
        
      SPService.wbevent(this.$store.state.user.currentUser.apiUrl, this.$store.state.user.currentUser.jwt, data);
    },

    openPendingInvitations() {
      this.$store.commit('user/SHOW_PENDINGINVITATION',true);      
    },

    revokePattern(user) {
      this.$store.commit('user/REVOKE_USERPATTERN',user);     
      this.$bvToast.toast( `${this.$t('The unlock pattern has been revoked for user')} ${user.username}.`, {
              title: this.$t('User Management'),            
              solid: true,
              variant: 'success',
              duration: 5000
            }); 
      const data = {
          fingerprint: this.$store.state.user.fingerprint,
          action: 'revokepattern',
          path:  window.location.pathname,
          data: window.location.href,
      }
        
      SPService.wbevent(this.$store.state.user.currentUser.apiUrl, this.$store.state.user.currentUser.jwt, data);
    },
    async switchMonthlyReport(user) {
      try
      {
        let text = '';
        if(user.config.receiveMonthlyReport) {
          text = this.$t('The user ') + user.username + this.$t(' will not receive montly report anymore.') 
        } else {
          text = this.$t('The user ') + user.username + this.$t(' will receive montly report.') 
        }

        let answer = await SPService.updateUser(API_URL.url, this.$store.state.user.currentUser.jwt, 
          {              
              "oid": user.oid,              
              "config": 
              {
                  "receiveMonthlyReport": !user.config.receiveMonthlyReport,                  
              }
          }
        );
        await this.$store.dispatch('fleet/updateUser', answer.data);
        // user.config.receiveMonthlyReport
        
        this.$bvToast.toast(text, {
              title: this.$t('User Management'),            
              solid: true,
              variant: 'success',
              duration: 5000
            }); 
      } catch(err) {
          this.$bvToast.toast(err.stack, {
              title: this.$t('User Management'),            
              solid: true,
              variant: 'warning',
              duration: 5000
            }); 
      }
        
    },
    async switchWeeklyReport(user) {
      try
      {
        let text = '';
        if(user.config.receiveWeeklyReport) {
          text = this.$t('The user ') + user.username + this.$t(' will not receive weekly report anymore.') 
        } else {
          text = this.$t('The user ') + user.username + this.$t(' will receive weekly report.') 
        }

        let answer = await SPService.updateUser(API_URL.url, this.$store.state.user.currentUser.jwt, 
          {              
              "oid": user.oid,              
              "config": 
              {
                  "receiveWeeklyReport": !user.config.receiveWeeklyReport,                  
              }
          }
        );
        await this.$store.dispatch('fleet/updateUser', answer.data);
        // user.config.receiveMonthlyReport
        
        this.$bvToast.toast(text, {
              title: this.$t('User Management'),            
              solid: true,
              variant: 'success',
              duration: 5000
            }); 
      } catch(err) {
          this.$bvToast.toast(err.stack, {
              title: this.$t('User Management'),            
              solid: true,
              variant: 'warning',
              duration: 5000
            }); 
      }
      },

    rowHovered(item){
        this.hoveredRow = item;
        this.$refs.table.refresh();
    },
    isHovered(item){
        return item == this.hoveredRow;
    },
      resetBtn(item) {        

        this.$dialog
          .confirm(this.$t('An email will be sent to the user in order to reset its password.'), { okText: this.$t('Yes'), cancelText: this.$t('No')})
          .then(async (dialog) => {
            AuthService.resetPasswordRequest(API_URL.url, item.email);   
            this.$bvToast.toast(this.$t('A reset password link has been sent to {email}', { email: item.email }), {
                title: this.$t('User Management'),            
                solid: true,
                variant: 'success',
                duration: 5000
              }); 

          })
          .catch(function() {
            
          });

        const data = {
          fingerprint: this.$store.state.user.fingerprint,
          action: 'resetuserpassword',
          path:  window.location.pathname,
          data: window.location.href,
      }
        
      SPService.wbevent(this.$store.state.user.currentUser.apiUrl, this.$store.state.user.currentUser.jwt, data);
          
    }
  },
  mounted() {
    this.$store.dispatch('fleet/loadUsers');
  }, 
  watch: {
    '$store.state.user.userEditorShow': async function(val,oldVal) {
      if(!val) {
        console.log('reloading after editor closing')
        await this.$store.dispatch('fleet/shortPoll');
        await this.$store.dispatch('fleet/loadUsers');
      }        
    },
    '$store.state.user.userDeletorShow': async function(val,oldVal) {
      if(!val) {
        console.log('reloading after deletor closing')
        await this.$store.dispatch('fleet/shortPoll');
         await this.$store.dispatch('fleet/loadUsers');
      }        
    },
    '$store.state.fleet.currentOrganization': async function(val,oldVal) {      
         await this.$store.dispatch('fleet/loadUsers');
    }
  }
};
</script>


<style lang="scss" scoped>

.b-table {
  .table {
    td {
      vertical-align: middle;
    }
  }
}

.table-striped tbody tr:nth-of-type(odd){
   background-color:#75b0a2c0;
}

.isakeynothovered {
    visibility: hidden;
}

.dg-content {
    text-align: center !important;
    font-size: 1.5rem;
    line-height: 28px;
    font-weight: 600;
    outline: none !important;
}

.dg-btn--ok {
    border: #99ec72;
    background-color: #b8f77e;
    color: white;
    border-radius: 3px;
    padding: .5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
}

.dg-btn--cancel {
    border: #e7e5e5;
    background-color: #e9e0e1;
    color: white;
    border-radius: 3px;
    padding: .5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
}
</style>
