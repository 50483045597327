<template>
  <b-modal hide-footer v-model="showModal" @hidden="close" :title="$t('View Pending Invitations')">
    <div>
      <b-card>
        <b-row v-for="invitation in $store.state.fleet.invitations" :key="invitation.oid">
          <b-col cols=4>
              {{ invitation.createdAt }}
          </b-col>
          <b-col cols=8>
              {{$t('Invitation sent to ') + invitation.email }}
          </b-col>
        </b-row>
      </b-card>
    </div>
  </b-modal>
</template>

<script>
import { SPService } from "@/services/sp.service";

export default {
  components: {    
  },
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
  },
  watch: {
    "$store.state.user.pendingInvitationShow": function(val) {
      this.showModal = val;
      if(val) {
        const data = {
          fingerprint: this.$store.state.user.fingerprint,
          action: 'userviewpendinginvitations',
          path:  window.location.pathname,
          data: window.location.href,
      }
        
       SPService.wbevent(this.$store.state.user.currentUser.apiUrl, this.$store.state.user.currentUser.jwt, data);
      }
    },    
  },
  methods: {
    
    close() {
      this.$store.commit("user/SHOW_PENDINGINVITATION", null);
    },

  },
  mounted() {
    this.$store.commit("user/SHOW_PENDINGINVITATION", null);
    
  },
  name: "PendingInvitationsModal"
};
</script>
<style lang="scss" scoped>
</style>
