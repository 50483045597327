<template>
  <b-modal hide-footer v-model="showUserEditor" @hidden="close" :title="$t('Send Invitation')">
    <div>
      <b-card>
        <validation-observer ref="observer" v-slot="{ handleSubmit }" >
          <b-form @submit.prevent="handleSubmit(apply)">
            <validation-provider :name="$t('Email')" :rules="{ required: true, regex: /^[^@]+@[^@]+\.[a-z]{2,}(_[a-z]+)*$/i }" v-slot="validationContext">
            <b-form-group
              label-cols-sm="3"
              :label="$t('Email:')"
              label-align-sm="left"
              label-for="email"
            >
              <b-form-input v-model="email"
                :state="getValidationState(validationContext)"
                aria-describedby="input-3-live-feedback"
                id="invitation_input_email"
              ></b-form-input>
              <b-form-invalid-feedback
                  id="input-3-live-feedback"
                >{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
            </validation-provider>

            <validation-provider :name="$t('Role')" :rules="{ required: !role || role === 0 || role === 3 || role === 4 || role === 11 }" v-slot="validationContext">
            <b-form-group label-cols-sm="3" :label="$t('Role:')" label-align-sm="left" label-for="role">
              <b-form-select
                v-if="!role || role === 0 || role === 3 || role === 4 || role === 11"
                v-model="role"
                :options="roles"
                :state="getValidationState(validationContext)"
                aria-describedby="input-2-live-feedback"
                id="invitation_input_role"
              ></b-form-select>
              <b-form-text v-else>mintt system user {{role}}</b-form-text>
              <b-form-invalid-feedback
                  id="input-2-live-feedback"
                >{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
            </validation-provider>

            <validation-provider :name="$t('Language')" :rules="{ required: true}" v-slot="validationContext">
            <b-form-group label-cols-sm="3" :label="$t('Language')" label-align-sm="left" label-for="Language">
              <b-form-select                
                v-model="preferredLanguage"
                :options="preferredLanguages"
                :state="getValidationState(validationContext)"
                aria-describedby="input-p2-live-feedback"
                id="invitation_input_language"
              ></b-form-select>              
              <b-form-invalid-feedback
                  id="input-p2-live-feedback"
                >{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
            </validation-provider>
            <span>{{$t('Give rights access to:')}}</span>
            <div v-for="rs of $store.state.fleet.roomsetList.filter(rs => rs.organizationLabel === $store.state.fleet.currentOrganization && rs.label.charAt(0) !== '_')" :key="rs.oid"  class="col-md-6 mb-30">            
              <div class="row">
                <div class="col-md-12"><span>{{ rs.label }}</span>                 </div>
                <div class="col-md-12">
                  <label class="switch switch-primary mr-3">
                  <input type="checkbox" :checked="hasRightsOn(rs)" @change="setRightsOn(rs, $event)"  />              
                  <span class="slider"></span>
                  </label>   
                </div>                            
              </div>                        
            </div>     

            <b-button  class="float-left" @click="close">
                    {{ $t('Cancel')}}
                </b-button>
                <b-button  :loading="requesting" variant="primary" class="float-right" type="submit" id="invitation_input_button">
                    {{ $t('Apply')}}
            </b-button>    
            

          </b-form>
        </validation-observer>
      </b-card>
    </div>
  </b-modal>
</template>

<script>
import { AuthService } from "@/services/auth.service";
import { SPService } from "@/services/sp.service";
import ROLES from "@/constants/roles";
import Multiselect from "vue-multiselect";
import { API_URL } from '@/.env'

var generator = require('generate-password');
 


export default {
  components: {    
    Multiselect,    
  },
  data() {
    return {
      requesting: false,
      showUserEditor: false,
      oid: null,
      create: false,
      username: null,
      password: null,
      showPassword: false,
      firstName: null,
      lastName: null,

      email: null,
      role: null,
      roles: [
        // { value: ROLES.kiosk, text: this.$t("Kiosk") },
        { value: ROLES.doctor, text: this.$t("Caregiver (can access images)") },
        { value: ROLES.admin, text: this.$t("Admin (can manage users, phones and rooms)") },
        { value: ROLES.medAdmin, text: this.$t("Medical Admin (can manage and access images)") }
      ],
      phone: null,
      isCall: null,
      notification: null,
      notifications: [
        { value: "0", text: this.$t('None') },
        { value: "1", text: this.$t("Phone Call") },
        { value: "2", text: this.$t("SMS") }
      ],
      enableBedExitNotification: null,
      enableFallNotification: null,
      preferredLanguage: null,
      preferredLanguages: [
        //{ value: "zh", text: "Chinese" },
        { value: "nl", text: this.$t("Dutch") },
        { value: "en", text: this.$t("English") },
        { value: "fr", text: this.$t("French") },
        //{ value: "de", text: "German" },
        //{ value: "it", text: "Italian" },
        //{ value: "ja", text: "Japanese" },
        //{ value: "pt", text: "Portugese" },
        //{ value: "ru", text: "Russian" },
        //{ value: "sp", text: "Spanish" }
      ],
      phoneCountries: ["BE", "FR", "DE", "US", "SW", "LU"],
      accesses: [],
      sendMailToUser: false,
      sendMailToAdmin: false,
      receiveMonthlyReport: false,
      receiveWeeklyReport: false,
      rights: [],
    };
  },
  computed: {
    options() {
      return this.$store.state.fleet.roomsetList.filter(r => r.organizationLabel === this.$store.state.fleet.currentOrganization && r.label.charAt(0) !== '_').map(p => {
        return { name: p.label };
      });
    }
  },
  watch: {
    "$store.state.user.sendInvitationShow": function(val) {
      this.showUserEditor = val;
    },    
  },
  methods: {
    hasRightsOn(rs) {
      if(!this.rights[rs.oid])
          this.rights[rs.oid] = true;
      return this.rights[rs.oid];
    },
    setRightsTo(rs, ev) {
      this.rights[rs.oid] = ev.target.checked;
    },
    validateAccesses() {
      if(this.roles !== 5) {
        return { required: true}
      } else {
        return {}
      }
    },
    generatePassword() {
       this.password = generator.generate({
          length: 6,
          numbers: true
        });
        this.showPassword = true
    },
    resetPassword() {
      this.password = generator.generate({
          length: 6,
          numbers: true
        });
      this.showPassword = true
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    close() {
      this.$store.commit("user/SHOW_SENDINVITATION", null);
    },

    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000)
      };
      this.value.push(tag);
      console.log(this.value);
    },
    async apply() {
      // here we know that the form is validated
      this.requesting = true
        
        let rsrights = [];
        console.log(this.rights)
        for(const rs in this.rights)
            if(this.rights[rs]) rsrights.push(rs);

        try
        {
          let answer = await SPService.createInvitation(API_URL.url, this.$store.state.user.currentUser.jwt, 
          {              
              "email": this.email,
              "targetLanguage": this.preferredLanguage,              
              "targetRole": this.role,
              "targetOrganizationLabel": this.$store.state.fleet.currentOrganization,
              "targetRoomsets": rsrights,
          }          
        );        
        this.$store.dispatch('fleet/loadUsers');

        if(answer.status !== 200)
            this.$bvToast.toast( `${this.$t('Error while sending invitation to ')} ${this.email}\n${answer.data.response.data.message}`, {
              title: this.$t('User Invitation'),            
              solid: true,
              variant: 'warning',
              duration: 5000
            });
          else             
            this.$bvToast.toast( `${this.$t('An invitation email has been sent to ')} ${this.email}`, {
              title: this.$t('User Invitation'),            
              solid: true,
              variant: 'success',
              id: 'alert_invitation_sent',
              duration: 5000
            });
        } catch(err) {          
            this.$bvToast.toast( `${this.$t('AError while sending invitation to  ')} ${this.email}`, {
              title: this.$t('User Invitation'),            
              solid: true,
              variant: 'warning',
              duration: 5000
            });
        }
        

      this.requesting = false
      // close editor
      this.$store.commit("user/SHOW_SENDINVITATION", null);

    }
  },
  mounted() {
    this.$store.commit("user/SHOW_SENDINVITATION", null);
    this.requesting = false;
    this.rights = [];
    this.role = ROLES.doctor;
    this.preferredLanguage = 'en';
    this.email = '';

  },
  name: "UserEditionModal"
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
</style>
